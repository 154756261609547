import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import SPRING_API_URL from "../utils/SpringApiUrl";
import axios from "axios";

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: "100%",
  margin: "0 auto",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ProductRegistrationForm = () => {
  const [barcode, setBarcode] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState({
    category1: "",
    category2: "",
    category3: "",
    category4: "",
    koreanName: "",
    englishName: "",
    expirationDate: new Date().toISOString().split("T")[0],
    distributor: "",
    receivedDate: new Date().toISOString().split("T")[0],
    vatRate: "12",
    wholesaleDiscountRate: "15",
    seniorDiscountRate: "10",
    initialStockBoxes: "",
    productsPerBox: "",
  });

  const handleBarcodeKeyDown = (e) => {
    if (e.key === "Enter" && barcode) {
      e.preventDefault();
      handleBarcodeSubmit();
    }
  };

  const handleBarcodeSubmit = async () => {
    setFetchLoading(true);
    const config = {
      method: "post",
      url: `${SPRING_API_URL}/register/crawl-product`,
      data: {
        barcode,
      },
    };
    try {
      const response = await axios.request(config);
      const data = response.data;
      if (data) {
        const { productName, category } = data;

        const categories = category.split(">");

        const [category1 = "", category2 = "", category3 = "", category4 = ""] =
          categories;

        setFormData((prevState) => ({
          ...prevState,
          category1,
          category2,
          category3,
          category4,
          koreanName: productName || "",
        }));
      } else {
        console.log("No product data found for this barcode");
        alert("No product data found for this barcode");
      }
    } catch (error) {
      console.error("Error fetching product info:", error);
      if (error.response) {
        console.error(
          "Server responded with:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      setFetchLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const config = {
      method: "post",
      url: `${SPRING_API_URL}/register/save-product`,
      data: {
        barcode,
        ...formData,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data) {
        setBarcode("");
        setFormData({
          category1: "",
          category2: "",
          category3: "",
          category4: "",
          koreanName: "",
          englishName: "",
          expirationDate: new Date().toISOString().split("T")[0],
          distributor: "",
          receivedDate: new Date().toISOString().split("T")[0],
          vatRate: "12",
          wholesaleDiscountRate: "15",
          seniorDiscountRate: "10",
          initialStockBoxes: "",
          productsPerBox: "",
        });
      }
    } catch (error) {
      console.error("Error saving product:", error);
      if (error.response) {
        console.error(
          "Server response:",
          error.response.status,
          error.response.data
        );
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <FormContainer component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" component="h1" gutterBottom>
        Product Registration
      </Typography>

      <TextField
        label="Barcode"
        value={barcode}
        onChange={(e) => setBarcode(e.target.value)}
        onKeyDown={handleBarcodeKeyDown}
        fullWidth
        variant="outlined"
      />
      <Button
        onClick={handleBarcodeSubmit}
        variant="contained"
        disabled={fetchLoading || !barcode}
      >
        {fetchLoading ? <CircularProgress size={24} /> : "Fetch Product Info"}
      </Button>

      <TextField
        label="Category 1"
        name="category1"
        value={formData.category1}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Category 2"
        name="category2"
        value={formData.category2}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Category 3"
        name="category3"
        value={formData.category3}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Category 4"
        name="category4"
        value={formData.category4}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Product Name (Korean)"
        name="koreanName"
        value={formData.koreanName}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Product Name (English)"
        name="englishName"
        value={formData.englishName}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Expiration Date"
        name="expirationDate"
        type="date"
        value={formData.expirationDate}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Distributor"
        name="distributor"
        value={formData.distributor}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Received Date"
        name="receivedDate"
        type="date"
        value={formData.receivedDate}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="VAT Rate (%)"
        name="vatRate"
        type="number"
        value={formData.vatRate}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Wholesale Discount Rate (%)"
        name="wholesaleDiscountRate"
        type="number"
        value={formData.wholesaleDiscountRate}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Senior Discount Rate (%)"
        name="seniorDiscountRate"
        type="number"
        value={formData.seniorDiscountRate}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Initial Stock (Boxes)"
        name="initialStockBoxes"
        type="number"
        value={formData.initialStockBoxes}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Products per Box"
        name="productsPerBox"
        type="number"
        value={formData.productsPerBox}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
      />

      <SubmitButton
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={submitLoading}
      >
        {submitLoading ? <CircularProgress size={24} /> : "Submit"}
      </SubmitButton>
    </FormContainer>
  );
};

export default ProductRegistrationForm;
