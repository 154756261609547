import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { BrowserMultiFormatReader } from "@zxing/browser";
import { NotFoundException } from "@zxing/library";
import SPRING_API_URL from "../utils/SpringApiUrl";
import axios from "axios";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: "800px",
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledVideo = styled("video")({
  maxWidth: "100%",
  border: "1px solid #ccc",
  borderRadius: "4px",
});
const BarcodeProductPage = () => {
  const [barcode, setBarcode] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [formData, setFormData] = useState({
    category1: "",
    category2: "",
    category3: "",
    category4: "",
    koreanName: "",
    englishName: "",
    expirationDate: new Date().toISOString().split("T")[0],
    distributor: "",
    receivedDate: new Date().toISOString().split("T")[0],
    vatRate: "12",
    wholesaleDiscountRate: "15",
    seniorDiscountRate: "10",
    initialStockBoxes: "",
    productsPerBox: "",
  });

  const handleBarcodeKeyDown = (e) => {
    if (e.key === "Enter" && barcode) {
      e.preventDefault();
      handleBarcodeSubmit();
    }
  };

  const handleBarcodeSubmit = async () => {
    setFetchLoading(true);
    const config = {
      method: "post",
      url: `${SPRING_API_URL}/register/crawl-product`,
      data: {
        barcode,
      },
    };
    try {
      const response = await axios.request(config);
      const data = response.data;
      if (data) {
        const { productName, category } = data;
        const categories = category.split(">");
        const [category1 = "", category2 = "", category3 = "", category4 = ""] =
          categories;

        setFormData((prevState) => ({
          ...prevState,
          category1,
          category2,
          category3,
          category4,
          koreanName: productName || "",
        }));
      } else {
        console.log("No product data found for this barcode");
        alert("No product data found for this barcode");
      }
    } catch (error) {
      console.error("Error fetching product info:", error);
      if (error.response) {
        console.error(
          "Server responded with:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      setFetchLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const config = {
      method: "post",
      url: `${SPRING_API_URL}/register/save-product`,
      data: {
        barcode,
        ...formData,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data) {
        setBarcode("");
        setFormData({
          category1: "",
          category2: "",
          category3: "",
          category4: "",
          koreanName: "",
          englishName: "",
          expirationDate: new Date().toISOString().split("T")[0],
          distributor: "",
          receivedDate: new Date().toISOString().split("T")[0],
          vatRate: "12",
          wholesaleDiscountRate: "15",
          seniorDiscountRate: "10",
          initialStockBoxes: "",
          productsPerBox: "",
        });
      }
    } catch (error) {
      console.error("Error saving product:", error);
      if (error.response) {
        console.error(
          "Server response:",
          error.response.status,
          error.response.data
        );
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleScan = () => {
    setScanning(true);
    const codeReader = new BrowserMultiFormatReader();
    codeReader.decodeFromVideoDevice(null, "video", (result, err) => {
      if (result) {
        setBarcode(result.text);
        setScanning(false);
        codeReader.stopContinuousDecode();
      }
      if (err && !(err instanceof NotFoundException)) {
        console.error(err);
      }
    });
  };

  return (
    <FormContainer component="form" onSubmit={handleSubmit}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Product Registration
      </Typography>

      <StyledPaper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Barcode"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
              onKeyDown={handleBarcodeKeyDown}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={handleBarcodeSubmit}
              variant="contained"
              disabled={fetchLoading || !barcode}
              fullWidth
            >
              {fetchLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Fetch Product Info"
              )}
            </Button>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          Barcode Scanner
        </Typography>
        <Button onClick={handleScan} variant="contained" fullWidth>
          {scanning ? <CircularProgress size={24} /> : "Scan Barcode"}
        </Button>
        <VideoContainer>
          <StyledVideo id="video" width="300" height="200" />
        </VideoContainer>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          Product Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category 1"
              name="category1"
              value={formData.category1}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category 2"
              name="category2"
              value={formData.category2}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category 3"
              name="category3"
              value={formData.category3}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Category 4"
              name="category4"
              value={formData.category4}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Name (Korean)"
              name="koreanName"
              value={formData.koreanName}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Name (English)"
              name="englishName"
              value={formData.englishName}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Expiration Date"
              name="expirationDate"
              type="date"
              value={formData.expirationDate}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Distributor"
              name="distributor"
              value={formData.distributor}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Received Date"
              name="receivedDate"
              type="date"
              value={formData.receivedDate}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="VAT Rate (%)"
              name="vatRate"
              type="number"
              value={formData.vatRate}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Wholesale Discount Rate (%)"
              name="wholesaleDiscountRate"
              type="number"
              value={formData.wholesaleDiscountRate}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Senior Discount Rate (%)"
              name="seniorDiscountRate"
              type="number"
              value={formData.seniorDiscountRate}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Initial Stock (Boxes)"
              name="initialStockBoxes"
              type="number"
              value={formData.initialStockBoxes}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Products per Box"
              name="productsPerBox"
              type="number"
              value={formData.productsPerBox}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </StyledPaper>

      <SubmitButton
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={submitLoading}
        size="large"
        fullWidth
      >
        {submitLoading ? <CircularProgress size={24} /> : "Submit"}
      </SubmitButton>
    </FormContainer>
  );
};

export default BarcodeProductPage;
